import { graphql, PageProps } from 'gatsby'
import React from 'react'

import { CheckoutRoutes } from '../../components/buy/checkout/CheckoutRoutes'
import { CheckoutPageQuery } from '../../graphql/gatsby'

const CheckoutRoutesPage: React.FC<PageProps<CheckoutPageQuery>> = ({ data }) => {
  return <CheckoutRoutes checkoutMarketingContent={data.sanityCheckoutMarketingContent} />
}

export const query = graphql`
  query CheckoutPage {
    ...sanityCheckoutMarketingContent
  }
`

export const sanityCheckoutMarketingContentFragment = graphql`
  fragment sanityCheckoutMarketingContent on Query {
    sanityCheckoutMarketingContent(_id: { regex: "/(drafts.|)checkoutMarketingContent/" }) {
      confirmationBanners {
        ...bannerWithText
      }
    }
  }
`

export default CheckoutRoutesPage

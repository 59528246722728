import { FilledTextFieldProps, TextField as MuiTextField } from '@material-ui/core'
import classnames from 'classnames'
import React, { useMemo } from 'react'
import { useController, UseControllerOptions, useFormContext } from 'react-hook-form'

import { formFieldLabelText } from '../../lib/cart/utils'

export interface TextFieldProps
  extends Omit<FilledTextFieldProps, 'error' | 'inputRef' | 'onChange' | 'value' | 'variant'> {
  fsExclude?: boolean
  inputMode?: Exclude<FilledTextFieldProps['inputProps'], undefined>['inputMode']
  label: string
  name: string
  rules?: UseControllerOptions['rules']
  step?: React.InputHTMLAttributes<unknown>['step']
}

const TextField: React.FC<TextFieldProps> = ({
  className,
  defaultValue = '',
  disabled = false,
  fsExclude = false,
  fullWidth = true,
  id,
  inputMode,
  inputProps = {},
  InputProps = {},
  label,
  name,
  onBlur,
  rules,
  type = 'text',
  InputLabelProps = {},
  ...other
}) => {
  const { control, errors } = useFormContext()
  const { field } = useController({ control, defaultValue, name, rules })
  const combinedOnBlur = useMemo(
    (): React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
      onBlur
        ? (e) => {
            // call event handler prior to react-hook-form's event handler in case handler
            // changes the field value, affecting validation
            onBlur(e)
            field.onBlur()
          }
        : field.onBlur,
    [field, onBlur]
  )

  return (
    <MuiTextField
      disabled={disabled}
      className={classnames(className, { 'fs-exclude': fsExclude })}
      fullWidth={fullWidth}
      id={id || name}
      inputProps={{ inputMode, ...inputProps }}
      InputLabelProps={{
        ...InputLabelProps,
        // workaround bug with label not shrinking after calling `setValue()`
        shrink: 'shrink' in InputLabelProps ? InputLabelProps.shrink : !!field.value,
      }}
      InputProps={{
        disableUnderline: true,
        type,
        ...InputProps,
      }}
      label={formFieldLabelText({ error: errors[name], label })}
      name={name}
      {...other}
      error={!!errors[name]}
      inputRef={field.ref}
      onBlur={combinedOnBlur}
      onChange={field.onChange}
      value={field.value}
      {...(rules?.required === true && { required: true })}
      variant="filled"
    />
  )
}

export default TextField
